/* eslint-enable complexity, no-redeclare, no-var, one-var */
import getWindowValues from '../../util/window.js';
import * as math from 'mathjs'

export default function calculateFormants ( buffer, fs, noverlap, windowFunc, fftSamples, alpha, preEmph, lifter, peakSearchOrder, threshold, channelIndex, dataLevel ) {
    if ( !( buffer && fs && noverlap && windowFunc && fftSamples && alpha && preEmph && lifter && peakSearchOrder ) ) return
    if ( noverlap < 0 || noverlap > fftSamples ) return

    const channelData = buffer.getChannelData( channelIndex )
    if ( !channelData ) return

    let [segments, minV, dynamicRange] = createSegmentList( channelData, fftSamples, noverlap )
    let windowValues = getWindowValues( fftSamples, fs, windowFunc, alpha )
    let fScale = fScaling( fftSamples, fs )
    const result = []
    segments.map( ( array, i ) => {
        if ( array.every( v => v == 0 ) ) return
        if ( skipData(array, dataLevel, minV, dynamicRange) ) return
        let [ iceps, AdftLog ] = cepstrumAnalysis( array, windowValues, preEmph, lifter )
        let formants = peakDetection( iceps, AdftLog, fScale, peakSearchOrder, threshold )
        let xSmp = ~~( fftSamples / 2 + ( fftSamples - noverlap ) * i )
        result.push( { formants: formants, xSmp: xSmp } )
    } )
    return result
}

function createSegmentList ( channelData, fftSamples, noverlap ) {
    let currentOffset = 0
    let result = []
    let currentMinV = Number.MAX_SAFE_INTEGER
    let currentMaxV = Number.MIN_SAFE_INTEGER
    while ( currentOffset + fftSamples < channelData.length ) {
        let samples = channelData.slice(
            currentOffset,
            currentOffset + fftSamples
        )
        const getMinV = function (a, b) {
            if( !a && b ) return Math.abs(b)
            else if ( a && !b) return Math.abs(a)
            else if( a && b) return Math.min(Math.abs(a), Math.abs(b))
        }
        const getMaxV = function (a, b) {
            if( !a && b ) return Math.abs(b)
            else if ( a && !b) return Math.abs(a)
            else if( a && b) return Math.max(Math.abs(a), Math.abs(b))
        }

        let minV = samples.reduce(getMinV)
        if( minV < currentMaxV ) currentMinV = minV

        let maxV = samples.reduce(getMaxV)
        if( maxV > currentMaxV ) currentMaxV = maxV

        result.push( samples )
        currentOffset += fftSamples - noverlap
    }
    const dynamicRange = 20 * Math.log10(currentMaxV / currentMinV)
    return [result, currentMinV, dynamicRange]
}

function fScaling ( fftSamples, fs ) {
    let n1 = Math.floor( ( fftSamples - 1 ) / 2 ) + 1
    let p1 = new Array( n1 ).fill( 0 )
    p1 = p1.map( ( v, i ) => v + i )
    let n2 = Math.floor( fftSamples / 2 )
    let p2 = new Array( n2 ).fill( 0 )
    p2 = p2.map( ( v, i ) => v + i - n2 )
    const freqDiff = fs / fftSamples
    const fScale = p1.concat( p2 ).map( v => v * freqDiff )
    return fScale
}

function skipData(array, dataLevel, minV, dynamicRange){
    let total = array.reduce( (sum, v) => {
        return sum + v * v;
    }, 0)
    if( total == 0 ) return true
    const value = total / array.length
    const avrDb = 10 * Math.log10( value )
    return dynamicRange - avrDb < dataLevel
}

function cepstrumAnalysis ( array, windowValues, preEmph, lifter ) {
    // pre-emphasis
    let data = array.map( ( v, i, array ) => ( i != 0 ) ? v - preEmph * array[ i - 1 ] : v - preEmph * v )
    // windowing
    let wind = Array.from( data.map( ( v, i ) => v * windowValues[ i ] ) )
    // spectrum
    let dft = math.fft( wind )
    // amplitude spectrum
    let Adft = dft.map( v => math.abs( v ) )
    // logarithmic amplitude spectrum
    let AdftLog = Adft.map( v => 20 * math.log10( v ) )
    // cepstrum
    let cps = math.ifft( AdftLog ).map( v => math.re( v ) )
    for ( let i = lifter; i < ( cps.length - lifter ); i++ ) cps[ i ] = 0
    let iceps = math.fft( cps ).map( v => math.re( v ) )

    return [ iceps, AdftLog ]
}

function peakDetection ( iceps, AdftLog, fscale, peakSearchOrder, threshold ) {
    if ( peakSearchOrder * 2 > iceps.length / 2 ) return

    let formants = []
    for ( let i = peakSearchOrder; i < iceps.length / 2 - peakSearchOrder; i++ ) {
        for ( let shift = 1; shift <= peakSearchOrder; shift++ ) {
            let rightCmp = iceps[ i ] - iceps[ i + shift ]
            let leftCmp = iceps[ i ] - iceps[ i - shift ]
            if ( !( rightCmp > 0 && leftCmp > 0 ) ) {
                if ( rightCmp < 0 ) {
                    i += shift - 1
                } else {
                    i += shift
                }
                break
            }
            if ( shift == peakSearchOrder && AdftLog[ i ] > threshold ) {
                formants.push( fscale[ i ] )
            }
        }
    }
    return formants
}
